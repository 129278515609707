<template>
    <div v-if="propUser !== null && user !== null && stats !== null" class="col-span-1 bg-purple-100 rounded-lg shadow">
        <div class="w-full flex items-center justify-between p-3 space-x-6">
            <div class="flex-1 truncate">
                <div class="flex items-start space-x-3">
                    <h3 class="text-gray-900 text-sm leading-5 font-medium truncate">{{ user.firstName + " " + user.lastName }}
                        <span class="ml-1 text-gray-500"> @{{ user.username !== null ? user.username : ""}}</span>
                    </h3>
                    <!--                    <span class="flex-shrink-0 inline-block px-2 py-0.5 text-teal-800 text-xs leading-4 font-medium bg-teal-100 rounded-full">Admin</span>-->
                </div>
                <div class="">
                    <p class="mt-1 text-left text-gray-700 text-sm font-light leading-5 truncate">{{user.profile.headline}}</p>
                </div>

            </div>
            <img class="w-14 h-14 bg-gray-300 rounded-full flex-shrink-0" :src="user.avatarUrl" alt="">
        </div>
        <div class="border-t border-gray-400">
            <div class="-mt-2px flex">
                <profile-card-stats :stats="stats"></profile-card-stats>
                <!--                <div class="w-0 flex-1 flex border-r border-gray-200">-->
                <!--                    <a href="#" class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 transition ease-in-out duration-150">-->
                <!--                        &lt;!&ndash; Heroicon name: mail &ndash;&gt;-->
                <!--                        <svg class="w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">-->
                <!--                            <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />-->
                <!--                            <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />-->
                <!--                        </svg>-->
                <!--                        <span class="ml-3">Email</span>-->
                <!--                    </a>-->
                <!--                </div>-->
                <!--                <div class="-ml-px w-0 flex-1 flex">-->
                <!--                    <a href="#" class="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 transition ease-in-out duration-150">-->
                <!--                        &lt;!&ndash; Heroicon name: phone &ndash;&gt;-->
                <!--                        <svg class="w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">-->
                <!--                            <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />-->
                <!--                        </svg>-->
                <!--                        <span class="ml-3">Call</span>-->
                <!--                    </a>-->
                <!--                </div>-->
            </div>
        </div>
    </div>
</template>

<script>
    import {jigo} from '../axios-auth'
    import ProfileCardStats from "../pages/profile/ProfileCardStats";
    export default {
        name: "UserPopCard",
        components: {ProfileCardStats},
        props: ['propUser'],
        created(){
            this.fetchStats();
            this.fetchUser();
        },
        watch: {
            propUser(){
                this.stats = null;
                this.user = null;
                if(this.propUser !== null) {
                    this.fetchUser();
                    this.fetchStats();
                }


            }
        },
        data(){
            return {
                user : null,
                stats: null,
                // stats: {
                //     doneCount: 12,
                //     subsCount:5,
                //     coLearnersCount: 200,
                //     communitiesCount: 4,
                //     communitiesSubsCount: 450,
                //     communitiesStarsCount: 5000,
                // },
            }
        },
        methods: {
            fetchUser(){
                jigo.get(`/v2/users/${this.propUser.username}/profile`)
                    .then((resp) => {
                        if (resp.status === 200){
                            this.user = resp.data
                        }
                    })
                    .catch((err) => {
                        console.error("ERROR: Loading user for card FAILED", err)
                    })
            },
            fetchStats(){
                jigo.get(`/v2/users/${this.propUser.username}/profile/stats`)
                    .then((resp) => {
                        if (resp.status === 200){
                            this.stats = resp.data
                        }
                    })
                    .catch((err) => {
                        console.error("ERROR: Loading stats for card FAILED", err)
                    })
            }
        }
    }
</script>

<style scoped>

</style>
