<template>
    <div class="w-full mx-auto">
        <dl class="flex justify-evenly rounded-lg bg-purple-100 shadow-lg">
            <div class="statDiv sm:border-0 sm:border-r">
                <dt class="popCardDT" id="item-100">
                    Done
                </dt>
                <dd class="popCardDD" aria-describedby="item-1">
                    {{ stats.doneCount }}
                </dd>
            </div>
            <div class="statDiv sm:border-0 sm:border-l sm:border-r">
                <dt class="popCardDT">
                    Learning
                </dt>
                <dd class="popCardDD">
                    {{ stats.subsCount }}
                </dd>
            </div>
            <div v-if="stats.communitiesCount" class="statDiv sm:border-0 sm:border-l sm:border-r">
                <dt class="popCardDT">
                    Communities
                </dt>
                <dd class="popCardDD">
                    {{ stats.communitiesCount }}
                </dd>
            </div>
            <div v-if="stats.communitiesCount" class="statDiv sm:border-0 sm:border-l sm:border-r">
                <dt class="popCardDT">
                    Subs
                </dt>
                <dd class="popCardDD">
                    {{ stats.communitiesSubsCount }}
                </dd>
            </div>
            <div v-if="stats.communitiesCount" class="statDiv sm:border-0 sm:border-l sm:border-r">
                <dt class="popCardDT">
                    Stars
                </dt>
                <dd class="popCardDD">
<!--                    5K-->
                    {{ stats.communitiesStarsCount }}
                </dd>
            </div>
            <div class="statDiv sm:border-0 sm:border-l">
                <dt class="popCardDT">
                    CoLearners
                </dt>
                <dd class="popCardDD">
                    {{ stats.coLearnersCount }}
                </dd>
            </div>
        </dl>
    </div>
</template>

<script>
    export default {
        name: "ProfileCardStats",
        props: {
            stats: {},
            popCard: {
                default: false
            },
        }
    }
</script>

<style scoped>
    .statDiv {
        @apply flex flex-col border-t border-purple-100 p-2 text-center
    }
    .popCardDT {
        @apply order-2 text-sm leading-6 font-medium text-gray-500
    }
    .popCardDD {
        @apply order-1 text-sm leading-none font-extrabold text-indigo-600
    }
    .profileDT {
        @apply order-2 mt-2 text-lg leading-6 font-medium text-gray-500
    }
    .profileDD {
        @apply order-1 text-5xl leading-none font-extrabold text-indigo-600
    }
</style>
