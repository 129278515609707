<template>
    <div>
        <div :id="`userCard${uniqueID}`" class="overflow-hidden">
            <router-link tag="div" :to="`/${propUser.username}`" class="cursor-pointer">
                <img class="inline-block rounded-full bg-gray-200" style="z-index: 10" :src="propUser.avatarUrl"
                     :class="{'h-6 w-6': size === 'sm', 'h-8 w-8': size === 'base',
                     'h-4 w-4': size === 'xs', 'h-10 w-10': size === 'md', 'h-12 w-12': size === 'lg'}"
                     alt="" @mouseenter="showTooltip" @mouseleave="hideTooltip">
            </router-link>
        </div>
        <div role="tooltip" :id="`userPopCard${uniqueID}`" v-if="showUserPopUp"   >
            <user-pop-card style="z-index: 100" :propUser="propUser"/>
        </div>
    </div>
</template>

<script>
    import { v4 as uuidv4 } from 'uuid';
    import * as Popper from '@popperjs/core'
    import UserPopCard from "@/components/UserPopCard";
    export default {
        name: "ProfileImageWithPopCard",
        components: {UserPopCard},
        // props: ['propUser', 'size'],
        props: {
            propUser: {},
            size: {
                default: 'base'
            },
        },
        data() {
            return {
                uniqueID: uuidv4(),
                showUserPopUp: false,
                popperInstance: null,
            }
        },
        methods: {
            //TODO: popper window doesn't adjust well when on windows borders and is not positioned straight above
            showTooltip() {
                this.showUserPopUp = true
                this.currentRequest = setTimeout(() => {
                    const trigger = document.querySelector(`#userCard${this.uniqueID}`);
                    const tooltip = document.querySelector(`#userPopCard${this.uniqueID}`);

                    // const trigger = this.$refs[`userCard${this.uniqueID}`];
                    // const tooltip = this.$refs[`userPopCard${this.uniqueID}`];

                    // const arrow = document.querySelector('#postsArrow');
                    tooltip.setAttribute('data-show', '');
                    this.popperInstance = Popper.createPopper(trigger, tooltip, {
                        placement: 'top',
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, 10],
                                },
                            },
                        ],
                    })


                }, 200)
            },
            hideTooltip(){
                clearTimeout(this.currentRequest)
                const tooltip = document.querySelector(`#userPopCard${this.uniqueID}`);
                tooltip.removeAttribute('data-show');
                this.destroyTooltip()
                this.showUserPopUp = false
            },
            destroyTooltip(){
                if (this.popperInstance) {
                    this.popperInstance.destroy();
                    this.popperInstance = null;
                }
            },
        },
    }
</script>

<style scoped>

</style>
